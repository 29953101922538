<template>
  <div
    class="relative w-full py-2 px-4 hover:bg-blue-100"
    @mouseover="showSubmenu = true"
    @mouseleave="showSubmenu = false"
  >
    <span class="flex items-center">
      {{ $t("FUNDER_OFFERS.CONTACTED_VIA") }}
      <icon-base
        icon="arrow-up"
        width="10"
        height="6"
        class="transform -rotate-90 ml-2"
      />
    </span>
    <ul
      v-if="showSubmenu"
      class="absolute top-0 left-0 transform -translate-x-full flex flex-col items-start justify-start bg-white shadow-md w-max text-left rounded cursor-pointer"
    >
      <li
        v-for="(method, key) in contactVia"
        :key="method"
        @click.prevent.stop="updateContactedVia(key)"
        class="py-2 px-4 hover:bg-blue-100 w-full"
      >
        <span class="flex items-center">
          <icon-base
            :icon="contactViaIcon(key)"
            iconColor="lightgray"
            height="12"
            width="12"
            viewBox="0 0 16 16"
            class="mr-2"
          />
          {{ method }}
        </span>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import type { IOffer } from "@/models/funders";
import type { PropType } from "vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { contactViaIcon } from "@/helpers/UI";
import type { IContactVia } from "@/models/options";

const props = defineProps({
  offer: {
    type: Object as PropType<IOffer>,
    required: true
  }
});

const { getters, dispatch } = useStore();

const showSubmenu = ref(false);

const contactVia = computed<IContactVia>(() => getters["options/contactVia"]);

const updateContactedVia = async (key: string | number) => {
  await dispatch("applications/updateContactedVia", {
    offer_ids: [props.offer.id],
    type: key
  });
  dispatch("applications/getOffers");
};
</script>
